// page-top

$(function(){
  var topBtn=$('#page-top');
  topBtn.hide();
  $(window).scroll(function(){
    if($(this).scrollTop()>80){
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function(){
    $('body,html').animate({
    scrollTop: 0},500);
    return false;
  });
});


// 外部リンクを別タブで開く

$(function() {
  $("a[href^='http://']").attr("target","_blank");
});
